import { Ref, useStore, computed, useContext, ref } from '@nuxtjs/composition-api';
import { User } from '~/types/user';
import { Resource } from '~/types/resource';
import { State } from '~/store';

interface UseDownload {
  isDownloading: Ref<boolean>;
  download: (fromModal?: boolean) => Promise<void>;
}

export const useDownload = (
  item?: Resource,
  amplitudeEvents?: Record<string, any>
): UseDownload => {
  const store = useStore<State>();
  const loggedInUser = computed<User>(() => store.getters.loggedInUser);
  const {
    $config,
    $amplitude,
    i18n,
    route,
    $constructHomeFeedDownloadEvent,
    $constructIntercomHomeFeedDownloadEvent,
    $intercom,
    $sendAlgoliaConversion,
    $quickDownload,
    $mzAnalyticsApi,
  } = useContext();
  const { baseURL } = $config;

  const isDownloading = ref<boolean>(false);

  const eventInstantFileDownloaded = (): void => {
    if (!item) {
      return;
    }
    const params = $constructHomeFeedDownloadEvent(item);
    const eventAction =
      params.File_Format === 'slide' || params.File_Format === 'sheet' ? 'Opened' : 'Downloaded'; // Exception for Google Slides or Sheets
    const pageName = amplitudeEvents?.pageName ? amplitudeEvents?.pageName : item.name;
    const eventParams = {
      Click_Type: 'Card',
      Click_Action: 'Download | Resource File',
      Click_Page_Position:
        amplitudeEvents?.pagePositionofCard + ' | ' + amplitudeEvents?.positionDetail,
      Click_Text: 'Icon',
      Click_Page_Occured:
        amplitudeEvents?.pageType + ' | ' + pageName + ' | ' + amplitudeEvents?.pageUrl,
      User_Download_Quota_Left: loggedInUser.value.subscription?.quota_left || undefined,
    };
    $amplitude.track('Resource | File ' + eventAction, {
      ...params,
      ...eventParams,
    });
    // @ts-ignore
    const identifyParams = new $amplitude.Identify().add('User_Life_Time_Download_Count', 1);
    $amplitude.identify(identifyParams);
  };

  const eventInstantFileDownloadedIntercom = (): void => {
    if (!item) {
      return;
    }
    const params = $constructIntercomHomeFeedDownloadEvent(item);
    const resourceType = item.is_free ? 'Free' : ' Premium';
    if (process.client && typeof window.gtag !== 'undefined') {
      window.gtag('event', 'resourceInteraction', {
        userUuid: loggedInUser.value.uuid,
        eventAction: 'Resource Interaction',
        resourceType,
        resourceName: item.name,
        resourceId: item.file_id,
        resourceLink: item.link,
      });
    }
    if (typeof $intercom !== 'undefined') {
      $intercom.trackEvent('Resource Downloaded', params);
    }
  };

  const download = async (fromModal?: boolean): Promise<void> => {
    if (fromModal) {
      $amplitude.track('Resource | Proceed to Download', {
        Button_Clicked: 'Proceed to Download',
        Page_Type_Name: 'Page',
        Page_Name: document.title,
        Page_Url: baseURL + route.value.path,
        Page_Country: i18n.locale,
      });
    }
    if (!item || !item.file_id) {
      return;
    }
    isDownloading.value = true;
    if (!item.file_id) {
      console.error('No file_id found for resource', item);
      return;
    }
    await $quickDownload(item.file_id);
    isDownloading.value = false;
    $mzAnalyticsApi({
      event: 'click',
      name: 'resource_card.download',
      entityType: item.type,
      entityId: item.old_id,
    });
    eventInstantFileDownloaded();
    eventInstantFileDownloadedIntercom();
    $sendAlgoliaConversion(String(item.old_id));
  };

  return {
    isDownloading,
    download,
  };
};
